<template>
  <v-container class="d-flex align-center justify-center height-100">
    <v-card class="d-flex align-center justify-center flex-column register-container dense">
      <img src="../assets/logo.png" class="logo mt-10">
      <span class="title-text font-weight-regular mt-4">Systems Test Tool - Sign In</span>
      <p class="description-text text-center grey--text text--darken-1 mt-4 mb-10">
        Welcome to Systems Test Tool, application for centralized test reports of NSoft owned devices.
      </p>
      <div ref="googleLoginBtn"></div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  metaInfo: {
    title: 'Sign In',
    meta: [
      {
        name: 'description',
        content: 'NSoft Devices - Sign In'
      }
    ]
  },

  methods: {
    ...mapActions({ login: "user/login" }),

    async handleCredentialResponse(response) {
      try {
        await this.login(response.credential);
        this.$router.replace('/reports');
      } catch (err) {
        console.error(err);
      }
    },

    onWindowLoad() {
      const gClientId = "344325573867-qld2832g1hfg56b8s79l6mj7mhm8oprk.apps.googleusercontent.com";
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
        auto_select: true
      });

      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        theme: "outline",
        size: "large",
        width: 366
      });
    }
  },

  mounted() {
    // Ensure that the function runs after window load
    if (document.readyState === "complete") {
      this.onWindowLoad();
    } else {
      window.addEventListener("load", this.onWindowLoad);
    }

    // Image loading test (optional)
    const tester = new Image();
    tester.onload = () => {
      console.log('Image is loaded');
    };
    tester.onerror = (err) => {
      console.log('Image is not loaded');
      console.error(err);
    };
    tester.src = "https://assets.nsoft.ba/public/demo/media/printLogo2.jpg";
  },

  beforeDestroy() {
    // Clean up the event listener when component is destroyed
    window.removeEventListener("load", this.onWindowLoad);
  }
};
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.description-text {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
}

.height-100 {
  height: 100vh;
}

.register-container {
  padding-left: 64px;
  padding-right: 64px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 440px;
  padding-bottom: 64px;

  .logo {
    width: 56px;
    height: 56px;
  }

  #googleLoginBtn {
    display: flex;
    box-shadow: 0 2px 7px 0 rgba(34, 34, 34, 0.15);
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
}
</style>
